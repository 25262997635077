<template>
  <Flicking
    :options="options"
    :plugins="pluginsCarruselInfoCardsPrimero"
    class="sm:hidden max-w-96 mx-auto"
    ref="carruselInfoCardsPrimero"
  >
    <TarjetaInfoCard
      v-for="(card, index) in infoCards"
      :info-card="card"
      :key="index"
      class="mb-16 mx-3 w-fit"
    />
    <template #viewport>
      <Paginador
        class="-bottom-1"
        :carrusel="carruselInfoCardsPrimero"
        :nombre-paginador="nombrePaginadorCarruselInfoCardsPrimero"
        :background="false"
      />
    </template>
  </Flicking>

  <Flicking
    :options="options"
    :plugins="pluginsCarruselInfoCardsSegundo"
    class="hidden sm:block xl:hidden"
    ref="carruselInfoCardsSegundo"
  >
    <div
      class="flex mb-16 gap-x-6 justify-center h-auto w-full"
      v-for="(agrupado, index) in infoCardsAgrupadas"
      :class="{ 'mr-6': index != infoCardsAgrupadas.length - 1 }"
      :key="index"
    >
      <TarjetaInfoCard :info-card="agrupado[0]" class="w-1/2" />
      <TarjetaInfoCard :info-card="agrupado[1]" class="w-1/2" />
    </div>
    <template #viewport>
      <Paginador
        class="-bottom-1"
        :carrusel="carruselInfoCardsSegundo"
        :nombre-paginador="nombrePaginadorCarruselInfoCardsSegundo"
        :background="false"
      />
    </template>
  </Flicking>

  <div class="hidden xl:flex xl:justify-between gap-x-6">
    <TarjetaInfoCard
      v-for="(info, index) in infoCards"
      :key="index"
      :info-card="info"
      :class="[`w-1/${infoCards.length} `]"
    />
  </div>
</template>
<script setup lang="ts">
  import Flicking from "@egjs/vue3-flicking";
  import TarjetaInfoCard from "@components/home/TarjetaInfoCard.vue";
  import infoCards from "src/data/info-cards.json";
  import { ref, computed } from "@vue/runtime-core";
  import Paginador from "@components/ui/Paginador.vue";
  import { plugins } from "@lib/bullets";

  const options = {
    align: "center",
    preventDefaultOnDrag: true,
  };

  const infoCardsAgrupadas = computed(() => {
    const infoCardsAgrupadas = [];
    for (let i = 0; i < infoCards.length; i += 2) {
      infoCardsAgrupadas.push([infoCards[i], infoCards[i + 1]]);
    }
    return infoCardsAgrupadas;
  });

  const carruselInfoCardsPrimero = ref<Flicking>();
  const nombrePaginadorCarruselInfoCardsPrimero =
    "paginador-carrusel-info-cards-primero";
  const pluginsCarruselInfoCardsPrimero = plugins(
    nombrePaginadorCarruselInfoCardsPrimero,
    false,
  );

  const carruselInfoCardsSegundo = ref<Flicking>();
  const nombrePaginadorCarruselInfoCardsSegundo =
    "paginador-carrusel-info-cards-segundo";
  const pluginsCarruselInfoCardsSegundo = plugins(
    nombrePaginadorCarruselInfoCardsSegundo,
    false,
  );
</script>
