<template>
  <div
    class="flex items-center justify-center gap-3 rounded border border-gray-50 shadow-md px-6 py-20 max-w-96"
  >
    <div class="flex flex-col items-center my-auto">
      <component :is="iconosRedes[infoCard.icon]" class="h-14 w-14 mb-5" />
      <h3 class="text-center font-bold text-green-500 text-xl">
        {{ infoCard.title }}
      </h3>
      <p class="text-center text-xl">
        {{ infoCard.description }}
      </p>

      <a
        class="pointer flex select-none rounded-md bg-green-500 px-6 py-4 font-semibold text-white transition-colors md:hover:bg-green-800 mt-6"
        :href="infoCard.link"
      >
        <span class="flex items-center">Ver más</span>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    BuildingOfficeIcon,
    TruckIcon,
    NewspaperIcon,
  } from "@heroicons/vue/24/outline";
  import FrestClubIcon from "@components/iconos/FrestClubIcon.vue";

  import type { Component } from "vue";
  import type { InfoCard } from "@lib/interfaces";

  const iconosRedes: Record<string, Component> = {
    BuildingOfficeIcon: BuildingOfficeIcon,
    TruckIcon: TruckIcon,
    NewspaperIcon: NewspaperIcon,
    FrestClubIcon: FrestClubIcon,
  };

  interface Props {
    infoCard: InfoCard;
  }
  defineProps<Props>();
</script>
